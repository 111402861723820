import React from 'react';
import { MdCalendarToday, MdOutlineAccessTime, MdOutlineEnergySavingsLeaf } from 'react-icons/md';
import { Image } from '@epcbuilder/lib/components';

interface Upgrade {
  id: string;
  title: string;
  content: string[];
  energyBillSaving: string;
  yearsGuarantee: string;
  installationDuration: string;
  image: string;
}

interface UpgradeDetails {
  upgrade: Upgrade;
}

const InstallMeasuresPage = () => {
  const handleClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="min-h-screen bg-repeat px-6 lg:bg-[url('/images/lg-background.png')] lg:p-0">
      <div className="relative z-10 mt-12 flex w-full flex-col md:mt-24">
        <div className="relative z-10 text-center">
          <h2 className="text-primary mb-5 font-sans text-5xl font-black uppercase md:text-[64px] md:leading-[90px]">
            home upgrades we offer
          </h2>
          <p className="m-auto font-sans text-xl leading-[30px] md:text-2xl md:leading-[48px] lg:w-[1155px]">
            We offer a comprehensive range of energy-efficient solutions to enhance your home’s comfort and reduce
            energy costs. Our expert services include:
          </p>
          <div className="xs:flex-col xs:items-center xs:flex mt-5 hidden font-bold">
            {upgrades.map((upgrade, index) => (
              <button
                key={index}
                onClick={() => handleClick(upgrade.id)}
                className="hover:text-primary cursor-pointer text-[20px] capitalize leading-10 lg:text-[24px]"
              >
                {upgrade.title.toLowerCase()}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div>
        {upgrades.map((upgrade, index) => (
          <div
            key={index}
            id={upgrade.id}
            className={`flex flex-col items-center lg:flex-row ${index < upgrades.length - 1 ? 'mt-24' : 'my-24'}`}
          >
            {index % 2 === 0 ? (
              <>
                <div className="order-2 w-full lg:order-none lg:w-1/2">
                  <Image
                    src={upgrade.image}
                    className="w-full rounded-[30px] lg:rounded-e-[50px] lg:rounded-s-none"
                    alt={`Upgrade ${index + 1}`}
                  />
                </div>
                <div className="order-1 mb-10 flex w-full flex-col items-center lg:order-none lg:w-1/2">
                  <UpgradeDetails upgrade={upgrade} />
                </div>
              </>
            ) : (
              <>
                <div className="mb-10 w-full lg:w-1/2">
                  <UpgradeDetails upgrade={upgrade} />
                </div>
                <div className="w-full lg:w-1/2">
                  <Image
                    src={upgrade.image}
                    className="w-full rounded-[30px] lg:rounded-e-none lg:rounded-s-[50px]"
                    alt={`Upgrade ${index + 1}`}
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const UpgradeDetails = ({ upgrade }: UpgradeDetails) => (
  <div className="m-auto space-y-5 lg:w-[393px] xl:w-[593px]">
    <h1 className="mb-5 text-center font-sans text-2xl font-black uppercase lg:mt-8 lg:text-start">{upgrade.title}</h1>
    <div className="mx-auto hidden lg:block">
      <div className="flex flex-row justify-between">
        <p className="mb-2 flex flex-row items-start gap-2 text-[18px] font-semibold">
          <MdOutlineEnergySavingsLeaf size={20} />
          Energy Bill Saving Per Year
        </p>
        <p className="items-end text-[18px] font-semibold italic">{upgrade.energyBillSaving}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p className="mb-2 flex flex-row items-start gap-2 text-[18px] font-semibold">
          <MdCalendarToday size={20} />
          Years Guarantee
        </p>
        <p className="items-end text-[18px] font-semibold italic">{upgrade.yearsGuarantee}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p className="mb-2 flex flex-row items-start gap-2 text-[18px] font-semibold">
          <MdOutlineAccessTime size={20} />
          Installation Duration
        </p>
        <p className="items-end text-[18px] font-semibold italic">{upgrade.installationDuration}</p>
      </div>
    </div>
    <p className="xs:text-[18px] text-center font-sans text-[16px] leading-[28px] md:leading-[32px] lg:text-start lg:leading-[36px]">
      {upgrade.content.map((paragraph, idx) => (
        <span key={idx}>
          {paragraph}
          {idx < upgrade.content.length - 1 && <br />}
          <br />
        </span>
      ))}
    </p>
    <div className="mx-auto block max-w-[433px] lg:hidden">
      <div className="flex flex-row justify-between">
        <p className="mb-2 flex flex-row items-start gap-2 text-[18px] font-semibold">
          <MdOutlineEnergySavingsLeaf size={20} />
          Energy Bill Saving Per Year
        </p>
        <p className="items-end text-[18px] font-semibold italic">{upgrade.energyBillSaving}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p className="mb-2 flex flex-row items-start gap-2 text-[18px] font-semibold">
          <MdCalendarToday size={20} />
          Years Guarantee
        </p>
        <p className="items-end text-[18px] font-semibold italic">{upgrade.yearsGuarantee}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p className="mb-2 flex flex-row items-start gap-2 text-[18px] font-semibold">
          <MdOutlineAccessTime size={20} />
          Installation Duration
        </p>
        <p className="items-end text-[18px] font-semibold italic">{upgrade.installationDuration}</p>
      </div>
    </div>
  </div>
);

const upgrades = [
  {
    id: 'loft-insulation',
    title: 'Loft Insulation',
    content: [
      'Loft insulation is an effective insulation measure that takes very little time to complete. It works as an insulator for decades. A quarter of your home’s heat is lost through the roof, so it is well worth insulating!',
      'Loft insulation is made of mineral wool or foam, the same as roof insulation. The suggested depth of fill for a standard loft is 270mm-300mm.',
    ],
    energyBillSaving: '£480',
    yearsGuarantee: 'Not Required',
    installationDuration: '1 Day',
    image: '/images/install-measures/loft-insulation.png',
  },
  {
    id: 'solar-pv',
    title: 'Solar Photovoltaic Panels',
    content: [
      'The electricity produced by PV panels is renewable because it is produced only from the sun. This electricity can power any appliances that are switched on while the surplus is exported to the electricity grid or stored in a battery.',
      'You can use electricity only when you need it. When you don’t, you can also choose to sell it back to the grid. It usually requires two people to install the system. Solar PV panels are made of silicon, silver, and glass. The average panel is 2 m2 . The average 3 bed would need 10 panels. Your home cannot be north facing, and you will most likely need to own your home for this install.',
    ],
    energyBillSaving: '£400',
    yearsGuarantee: '25 years',
    installationDuration: '1-3 Days',
    image: '/images/install-measures/solar-photovoltaic-panel.png',
  },
  {
    id: 'heating-controls',
    title: 'Heating Controls',
    content: [
      `Heating controls manage when heating is on and room temperatures. They vary from manual to smart systems. Many people don't understand or use them effectively, resulting in higher electricity bills.`,
      'For a basic setup, central heating needs a programmer, room thermostat, and TRVs. Additional functions can enhance efficiency. Upgrading controls without replacing the boiler is possible. Modern options offer improved accuracy and energy-saving features.',
    ],
    energyBillSaving: '£105',
    yearsGuarantee: '2 years',
    installationDuration: '1 Day',
    image: '/images/install-measures/heating-controls.png',
  },
  {
    id: 'air-source-heat-pumps',
    title: 'Air Source Heat Pumps',
    content: [
      'An air source heat pump (ASHP) can provide all the heating and hot water you need. Most heat pumps do not require planning permission.',
      'An ASHP simply takes air from around your home, and converts it into fuel to heat your home and water.',
      'ASHPs are about 1.5 meters tall. They must be situated on a flat surface outside the property. The typical household will cut their house emissions by 44% per year when this measure is fitted.',
    ],
    energyBillSaving: 'Varies',
    yearsGuarantee: '5 years',
    installationDuration: '2-5 Days',
    image: '/images/install-measures/air-source-heat-pumps.png',
  },
  {
    id: 'boiler',
    title: 'Boiler',
    content: [
      'Heating and hot water accounts for over half of what you spend in a year on energy bills, so an efficient boiler makes a big difference.',
      'All modern, well-maintained boilers burn their fuel efficient, but they inevitably lose some heat in the hot gases that escape up the flue (a pipe used to extract fumes and supply fresh air to the boiler).',
      'Condensing boilers are more efficient, as they recover more heat from the exhaust flue gas and use it to heat the central heating water, making it more efficient than older boilers.',
    ],
    energyBillSaving: '£190',
    yearsGuarantee: '2 years',
    installationDuration: '1 Day',
    image: '/images/install-measures/boiler.png',
  },
  {
    id: 'internal-wall-insulation',
    title: 'Internal Wall insulation',
    content: [
      'Solid walls lose twice as much heat when compared to cavity walls, so insulating them makes a lot of sense!',
      'Installation involves the attachment of a framework of wooden battens (50mm deep) to the wall, and the space between the battens is filled with insulation material to keep your property warm.',
      'This kind of insulation is most often made of wool fiber. This is then plastered over. All wall fittings, radiators, sockets, and skirting will temporarily need to be removed.',
    ],
    energyBillSaving: '£465',
    yearsGuarantee: '25 years',
    installationDuration: '3-5 Days',
    image: '/images/install-measures/internal-wall-insulation.png',
  },
  {
    id: 'cavity-wall-insulation',
    title: 'Cavity Wall Insulation',
    content: [
      'Cavity walls are formed by two walls of brickwork built close together, and between this insulation can be administered.',
      `Most homes built after 1920 have cavities, whereas older homes tend to have solid walls. There are many types of CWI for different property types, including mineral wool, bead, or foam.`,
      'Some CWl materials may not be suitable for every cavity therefore a specialist survey is required. It can take half a day to conduct the initial technical survey and fill in between the bricks.',
    ],
    energyBillSaving: '£395',
    yearsGuarantee: '25 years',
    installationDuration: 'Half a Day',
    image: '/images/install-measures/cavity-wall-insulation.png',
  },
  {
    id: 'roof-isulation',
    title: 'Room in roof insulation',
    content: [
      `Room in roof insulation (RIRI) can make significant savings on your fuel bills as over 25% of the property's heat is lost through the roof.`,
      'If you use your loft as a living space then you need to make sure that all the walls and ceilings are insulated.',
      'RIRI often requires the laying of 300mm of wool or similar insulation material within the residual space with insulation boards fitted to the walls. The walls will be plastered over afterwards.',
    ],
    energyBillSaving: '£480',
    yearsGuarantee: '25 years',
    installationDuration: '2-3 Days',
    image: '/images/install-measures/room-in-roof-insulation.png',
  },
];

export default InstallMeasuresPage;
