import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavigationHandler } from '@epcbuilder/lib/components/Layout';
import { ReferrerCookieHandler } from '@epcbuilder/lib/components/ReferrerCookieHandler';
import FAQ from '@/app/faq';
import LandingPage from '@/app/landing';
import Privacy from '@/app/privacy';
import QuickWins from '@/app/quickwins';
import Terms from '@/app/terms';
import WhatToExpect from '@/app/whattoexpect';
import InstallMeasures from './app/installmeasures';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ReferrerCookieHandler>
        <NavigationHandler>
          <Routes>
            <Route path="/">
              <Route index element={<LandingPage />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="terms" element={<Terms />} />
              <Route path="what-to-expect" element={<WhatToExpect />} />
              <Route path="quick-wins" element={<QuickWins />} />
              <Route path="install-measures" element={<InstallMeasures />} />
            </Route>
          </Routes>
        </NavigationHandler>
      </ReferrerCookieHandler>
    </BrowserRouter>
  );
};

export default AppRoutes;
